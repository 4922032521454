import { i18n } from '@lingui/core';

export const locales = {
  en: 'English',
  it: 'Italiano',
  es: 'Español',
};

export async function activateLocale(locale) {
  // Disable languages until fully implemented
  // locale = locale || detect(fromStorage('lang'), fromNavigator(), 'en');
  locale = 'en';
  if (!locales[locale]) locale = 'en';
  const { messages } = await import(`./locales/${locale}`);
  i18n.load(locale, messages);
  i18n.activate(locale);
  if (typeof window !== 'undefined' && window.localStorage?.setItem != null) {
    window.localStorage.setItem('lang', locale);
  }
}
